<template>
  <div class="view ">
    <div class="view-top small-screen">
      <div class="view-detail">
        <p class="common-tip">资金管理</p>
        <ul class="detail-box">
          <li class="detail-item border-r">
            <div class="detail-pic">
              <img alt="" src="https://file.jingpingo.com/supply/material/0f0566da-b15d-47fe-a97a-b4664c24a20e.png">
            </div>
            <div>
              <p class="detail-text">可用余额</p>
              <p class="detail-price">￥{{ Money }}</p>
            </div>
          </li>
          <li class="detail-item">
            <div class="detail-pic">
              <img alt="" src="https://file.jingpingo.com/supply/material/c9111339-91f7-4956-bf5f-477dc0e2d9f6.png">
            </div>
            <div>
              <p class="detail-text">待结算</p>
              <p class="detail-price">￥ {{ waitMoney }}</p>
            </div>
          </li>
        </ul>
        <div class="detail-bottom">
          <div>
            <span class="btn invest-btn" @click="onCz">充值</span>
            <span class="btn" @click="onTx">提现</span>
          </div>
        </div>
      </div>
      <div class="view-census">
        <p class="common-tip">交易数据</p>
        <div class="dates">
          <el-date-picker v-model="value2" :clearable="false" :picker-options="pickerOptions" align="right"
            end-placeholder="结束日期" range-separator="至" size="small" start-placeholder="开始日期" type="daterange" unlink-panels
            @change="onDateStartEndChange">
          </el-date-picker>
        </div>
        <ul class="detail-box">
          <li v-for="(item, index) in amountPrice" :key="index" class="detail-list">
            <div class="detail-item">
              <div class="detail-pic">
                <img :src="item.key === '2' ? img2 : img1" alt="">
              </div>
              <div class="item-right">
                <p class="detail-text">{{ item.name }}</p>
                <p class="detail-price">￥{{ item.value }}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="view-gather">
      <p class="common-tip">财务汇总</p>
      <div class="view-gather-head">
        <div class="view-gather-tabs">
          <el-radio v-model="radio" class="mar_right" label="1" @input="onInput">日汇总</el-radio>
          <el-radio v-model="radio" class="mar_right" label="2" @input="onInput">月汇总</el-radio>
        </div>
        <div class="view-gather-date">
          <el-date-picker v-model="months" :clearable="false" :type="radio == '1' ? 'month' : 'year'" size="small"
            @change="onDateChange">
          </el-date-picker>
        </div>
        <el-button class="view-gather-down" disabled size="small" type="primary">下载{{ radio == '1' ? '月' : '年'
        }}账单</el-button>
      </div>
      <div class="container">
        <div class="left"></div>
        <div class="right">
          <div class="right-wrapper">
            <el-table :data="tableData" border height="300">
              <el-table-column align="center" label="序号" prop="id" width="80">
              </el-table-column>
              <el-table-column :label="radio == '1' ? '日期' : '月份'" align="center" prop="date">
              </el-table-column>
              <el-table-column align="center" label="收入" prop="revenue">
              </el-table-column>
              <el-table-column align="center" label="支出" prop="spending">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>


      <el-dialog :before-close="handleClose" :show-close="false" :title="wxDisalog ? '微信支付' : '充值方式'"
        :visible.sync="dialogVisible" width="600px">
        <el-dialog :close-on-click-modal="false" :show-close="false" :visible.sync="innerVisible" append-to-body title="充值金额" width="30%">
          <el-form ref="ruleForm" :model="wxPay" :rules="rules">
            <el-form-item label="金额:" prop="money">
              <el-input v-model="wxPay.money" placeholder="请输入充值金额" type="number"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="canCal">取 消</el-button>
            <el-button size="small" type="primary" @click="paySave('ruleForm')">充 值</el-button>
          </span>
        </el-dialog>
        <span>{{ wxDisalog ? '请使用微信扫码支付' : '请选择充值方式' }}</span>
        <div v-show="!wxDisalog" class="pay">
          <div v-for="item in payList" :key="item.name" :class="{ 'active': isactive == item.id }"
            class="pay-item" @click="tabPay(item.id, item)">
            <img :src="item.img" class="pay-img" />
            <p>{{ item.name }}</p>
          </div>
        </div>
        <div v-show="wxDisalog" class="codeBox">
          <vueQr :logo-src="require('@/assets/logos/wechat.png')" :margin="30" :size="300" :text="qrcode"
            :white-margin="true" />
          <!-- <h3 style="color:red;margin-top: 20px;">充值金额：{{ wxPay.money }} 元 </h3> -->
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="canCal">取 消</el-button>
          <el-button size="small" type="primary" @click="saveClick">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { pickerOptions } from '@/views/User/FinancialManagement/dateCollation'
import {
  getMoney,
  getAmountInfo,
  getAmountOverviewList,
  getPreWithdrawals,
  getWithdrawalsPre,
  getWaitAmount,
  Payonli
} from '../../../../api/finance/stream'
import { dateFormat } from '@/utils/utils'
import vueQr from 'vue-qr'


export default {
  name: 'GeneralView',
  components: { vueQr },
  data() {
    return {
      img1: 'https://file.jingpingo.com/supply/material/f4ec4ec1-56dc-45be-a276-a13a9084a0ba.png',
      img2: 'https://file.jingpingo.com/supply/material/12e5b61d-f4fc-4783-8f66-36a51be4265a.png',
      amountPrice: [],  // 交易数据
      // 滑块默认值
      value: true,
      // 单选框
      radio: '1',
      // 选择月
      months: '',
      // 表格数据
      tableData: [],
      // 时间带快捷选项
      pickerOptions: pickerOptions,
      value2: '',
      Money: '',
      waitMoney: null,
      dialogVisible: false,
      payList: [
        { id: 1, name: '微信支付', type: 'wx', img: require('@/assets/logos/wechat.png') },
        { id: 2, name: '支付宝', type: 'ali', img: require('@/assets/logos/alipay.png') },
        { id: 3, name: '线下支付', img: require('@/assets/logos/yu.png') },
      ],
      isactive: 1,
      qrcode: '',
      wxDisalog: false,
      innerVisible: false,
      wxPay: {
        money: '',
        payType: 'wx'
      },
      rules: {
        money: [{ required: true, message: '请输入充值金额', trigger: 'blur' },]
      }
    }
  },
  created() {
    this.initDate()
  },
  methods: {
    onTx() {
      getWithdrawalsPre().then((res) => {
        if (res.data) {
          this.$router.push({ path: '/user/fina/withdrawDeposit' })
        } else {
          this.$message.error('已存在审核中的记录')
        }
      })
    },
    // 清除表单数据
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    // 充值取消
    canCal() {

      if (this.innerVisible) {
        this.innerVisible = true ? this.wxDisalog = false : this.wxDisalog = true
        this.resetForm()
      } else {
        this.wxDisalog = false
        this.dialogVisible = false
      }
    },
    // 确定充值金额
    paySave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.innerVisible = false
          Payonli(this.wxPay).then(res => {
            console.log('确定充值金额', res)
            if (this.isactive == 1) {
              this.wxDisalog = true
              this.qrcode = res.data.codeUrl
            }
            if (this.isactive == 2) {
              window.open(res.data, '_blank')
              this.innerVisible = false
              this.dialogVisible = false
            }
            this.resetForm()
          })

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 选择充值金额弹窗
    saveClick() {
      if (this.isactive != 3) {
        this.innerVisible = true
      } else {
        getPreWithdrawals().then((res) => {
          if (res.data) {
            this.$router.push({ path: '/user/fina/recharge' })
          } else {
            this.$message.error('已存在审核中的记录')
          }
        })
      }
    },
    // 打开充值类型弹窗
    handleClose() {
      this.dialogVisible = true
    },
    // 选择充值方式
    tabPay(row, item) {
      this.isactive = row
      this.wxPay.payType = item.type
    },
    // 打开充值类型弹窗
    onCz() {
      this.dialogVisible = true
      // getPreWithdrawals().then((res) => {
      //   if (res.data) {
      //     this.$router.push({path:'/user/fina/recharge'})
      //   } else {
      //     this.$message.error('已存在审核中的记录')
      //   }
      // })
    },
    // 滑块事件
    onChange(e) {
      this.$message.success('修改成功')
    },
    onDateStartEndChange(e) {
      let starts = dateFormat(e[0])
      let ends = dateFormat(e[1])
      const obj = {
        startTime: starts.split(' ')[0],
        endTime: ends.split(' ')[0]
      }
      this.timeFn(obj)
      console.log(23454, obj)
    },
    // 单选框事件
    onInput(e) {
      let years = new Date().getFullYear()
      let month = new Date().getMonth() + 1
      if (month < 10) month = '0' + month
      month = `${years}-${month}`
      '1' === e ? this.initTableInfo({ param: month }) : this.initTableInfo({ param: years })
    },
    // 选择时间事件
    onDateChange(e) {
      let years = e.getFullYear()
      let month = e.getMonth() + 1
      if (month < 10) month = '0' + month
      this.months = `${years}-${month}`
      '1' === this.radio ? this.initTableInfo({ param: this.months }) : this.initTableInfo({ param: years })
    },
    initDate() {
      let years = new Date().getFullYear()
      let month = new Date().getMonth() + 1
      if (month < 10) month = '0' + month
      this.months = `${years}-${month}`
      const param = {
        param: this.months
      }
      console.log(45345445, this.months)
      getMoney().then(res => {
        this.Money = res.data.toFixed(2)
      })
      this.timeFn()
      this.initTableInfo(param)
      getWaitAmount().then(res => {
        this.waitMoney = res.data.toFixed(2)
      })
    },
    timeFn(v) {
      if (v === undefined) v = {}
      getAmountInfo(v).then((res) => {
        if (res.ok) {
          this.amountPrice = res.data
          this.amountPrice.forEach(item => {
            item.value = item.value.toFixed(2)
          })
        }
      })
    },
    initTableInfo(v) {
      getAmountOverviewList(v).then((res) => {
        if (res.ok) {
          this.tableData = res.data
          if (this.tableData.length) {
            this.tableData.forEach((item, index) => {
              item['id'] = index + 1
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.codeBox {
  margin: 12px 0 0 0;
  padding: 20px 0;
  min-height: 300px;
  text-align: center;
  background: #F5F8FC;
}

.pay {
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding: 35px;

  .pay-item {
    cursor: pointer;
    padding: 25px 35px;
    border-radius: 8px;

    &:hover {
      box-shadow: 0 0 16px 0 #cfc8c8;
    }
  }

  .pay-img {
    width: 80px;
    height: 80px;
    cursor: pointer;
  }

  .active {
    border: 2px solid rgb(221, 11, 11);
    border-radius: 8px;
  }
}


.surver-font {
  font-size: 18px;
  color: #2b2b2b;
}

.surver-color99 {
  color: #999;
  font-size: 14px;
}

.cards-item-left {
  margin-left: 10px;
}

.surver-top {
  margin-top: 20px;
}

.surver-acct {
  display: flex;
  align-items: center;
  width: 49.5%;
}

.surver-color {
  font-size: 14px;
  color: #555;
}

.surver-price {
  font-weight: 700;
  font-size: 24px;
}

.cards-color {
  font-size: 14px;
  color: #666;
  margin-left: 25px;
}

.cards-icon {
  font-weight: 700;
  color: #29a6ff;
  font-size: 14px;
}

.surver {
  padding: 0 10px;

  .surver-dispaly {
    display: flex;
    justify-content: space-between;
  }
}

.surver-title {
  font-size: 14px;
  font-weight: 700;
  color: #2b2b2b;
}

.surver-title::before {
  content: "";
  border-left: 3px solid #2ba7ff;
  padding-left: 10px;
}

.martop {
  margin-bottom: 25px;
}

.container {
  display: flex;
}

.left {
  width: 0px;
}

.right {
  flex: 1;
  position: relative;
}

.right-wrapper {
  //position: absolute;
  //width: 100%;
}

.dates {
  position: absolute;
  right: 30px;
  top: 65px;
}

.mar_right {
  margin-right: 20px;
}

.view-gather-down {
  position: absolute;
  left: 0;
}

.view-gather-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.view-gather-head {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}

.view-gather {
  margin-top: 16px;
  padding: 30px;
  background-color: #fff;
}

.common-tip::before {
  content: "";
  display: inline-block;
  margin-right: 4px;
  width: 3px;
  height: 12px;
  background: #1890ff;
  border-radius: 0 2px 0 2px;
}

p {
  -webkit-margin-before: 0;
  margin-block-start: 0;
  -webkit-margin-after: 0;
  margin-block-end: 0;
}

ul {
  padding: 0;
}

.common-tip {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  color: #2b2b2b;
}

.view {

  //min-width: 1550px;
  .detail-list:first-child::before {
    content: "";
    position: absolute;
    top: 23px;
    right: 0;
    width: 1px;
    height: 40px;
    background: #eee;
  }

  .detail-list {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    position: relative;

    .detail-item {
      padding-left: 20px;
    }
  }

  .detail-price {
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    color: #2b2b2b;
  }

  .detail-text {
    margin-bottom: 7px;
    font-size: 14px;
    line-height: 14px;
    color: #555;
  }

  .detail-pic {
    margin-right: 8px;
    width: 50px;
    height: 50px;

    img {
      width: 100%;
    }
  }

  .border-r::before {
    content: "";
    position: absolute;
    top: 5px;
    right: 0;
    width: 1px;
    height: 40px;
    background: #eee;
  }

  .border-r {
    position: relative;
  }

  .detail-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 30px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .detail-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .view-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    .view-detail {
      width: 580px;
      height: 200px;
      background-color: #fff;

      .common-tip {
        padding: 30px 30px 27px;
      }

      .detail-bottom {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-top: 29px;
        height: 50px;
        padding: 0 30px;
        font-size: 14px;
        color: #2b2b2b;
        background-color: #fafafa;

        .btn.invest-btn {
          margin-right: 10px;
          color: #fff;
          background: #2ba7ff;
        }

        .btn {
          display: inline-block;
          width: 68px;
          height: 28px;
          text-align: center;
          line-height: 28px;
          border: 1px solid #2ba7ff;
          font-size: 14px;
          color: #2ba7ff;
          border-radius: 4px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          cursor: pointer;
        }

        .switch-box {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
        }

        .edit-btn {
          margin: 0 24px 0 10px;
          font-size: 18px;
          color: #2ba7ff;
          cursor: pointer;
        }
      }
    }

    .view-census {
      position: relative;
      padding-top: 30px;
      width: calc(100% - 596px);
      background: #fff;

      .item-des {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 15px 0 0 107px;
        font-size: 14px;
        color: #999;

        .line {
          margin: 0 20px;
          width: 1px;
          height: 20px;
          background: #ddd;
        }

        span {
          font-size: 18px;
          color: #2b2b2b;
        }
      }

      .common-tip {
        margin-bottom: 80px;
        padding-left: 30px;
      }

      .detail-item {
        position: relative;
      }

      .item-right {
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
      }
    }
  }
}
</style>
